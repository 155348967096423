<template>
  <section class="container my-20 flex max-lg:flex-col">
    <div class="lg:w-2/4 xl:w-1/3">
      <NuxtPicture
        src="/office-image.jpg"
        alt="BTTR Office"
        :img-attrs="{class: 'size-full object-cover object-center'}"
      />
    </div>
    <div class="flex flex-col gap-y-12 bg-white p-6 text-black md:p-12 lg:w-2/4 xl:w-2/3">
      <h2>
        Aan de slag met een eigen AI-applicatie binnen jouw organisatie?
      </h2>
      <SofieForm form-id="19" />
    </div>
  </section>
</template>
<style scoped>
:deep(form) {
  gap: 0 theme('spacing.8');
  @apply grid max-w-4xl;
}

:deep(.formkit-actions) {
  margin-top: theme('spacing.4');
}

@screen sm {
  :deep(form) {
    grid-template-columns: 1fr 1fr;
  }

  :deep(.formkit-actions) {
    grid-column: 1 / span 2;
  }
}
</style>
